export const CLOSE_TOAST = 'CLOSE_TOAST';
export const OPEN_AND_SET_TOAST_CONTENT = 'OPEN_AND_SET_TOAST_CONTENT';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const OPEN_AND_SET_MODAL_CONTENT = 'OPEN_AND_SET_MODAL_CONTENT';
export const CLOSE_LOADER = 'CLOSE_LOADER';
export const OPEN_LOADER = 'OPEN_LOADER';
export const SAVE_PLAN = 'SAVE_PLAN';
export const SAVE_QUERY = 'SAVE_QUERY';
export const SAVE_LOAN = 'SAVE_LOAN';
export const SAVE_CARD = 'SAVE_CARD';
export const SAVE_DRAWER = 'SAVE_DRAWER';
export const SAVE_MENU = 'SAVE_MENU';
export const SAVE_AUTH = 'SAVE_AUTH';
export const LOG_OUT = 'LOG_OUT';
export const ME = 'ME';
export const COUNTRY = 'COUNTRY';
export const SAVE_MENU_VALUE = 'SAVE_MENU_VALUE';
export const USERDETAIL = 'USERDETAIL';
export const TRANSACTIONDETAIL = 'TRANSACTIONDETAIL';
export const LOADINGSTATE = 'LOADINGSTATE';
export const OPENSTATE = 'OPENSTATE';
